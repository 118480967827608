import { isEmpty } from 'lodash';
import moment from 'moment';
import { Maybe, Tour } from '../../_generated/types';
import { IdVerification } from '../graphql/graphql';
import { BlockStatus, DeleteStatus, ReviseStatus } from './enums';

export default {
  SearchPage: {
    searchHeading: 'Search for Prospect',
    searchError: 'Error occurred.',
    searchLoading: 'Loading...',
    notFound: 'No prospects found, please double check the email address',
    found: (length: number) => `Found ${length} prospect${length > 1 ? 's' : ''}`,
    deleteHeading: 'Delete Map',
    deleteBody: 'Are you sure you want to delete this map? This action cannot be undone.',
    deleteConfirm: (status: DeleteStatus) => {
      switch (status) {
        case DeleteStatus.DELETED:
          return 'Deleted!';
        case DeleteStatus.ERROR:
          return 'Error';
        case DeleteStatus.UNSTARTED:
          return 'Delete';
      }
    },
    deleteCancel: 'Cancel',
    blockMapHeading: 'Block Map?',
    blockConfirm: (status: BlockStatus) => {
      switch (status) {
        case BlockStatus.UNSTARTED:
          return 'Confirm';
        case BlockStatus.ERROR:
          return 'Error';
        case BlockStatus.BLOCKED:
          return 'Blocked!';
      }
    },
    reviseHeading: 'Revise Map',
    reviseBody: 'Are you sure you want to revise this map? This action cannot be undone.',
    reviseConfirm: (status: ReviseStatus) => {
      switch (status) {
        case ReviseStatus.REVISED:
          return 'Revised!';
        case ReviseStatus.ERROR:
          return 'Error';
        case ReviseStatus.UNSTARTED:
          return 'Revise';
      }
    },
    reviseCancel: 'Cancel',
  },
  PathDisplay: {
    walking: 'Walking Route',
    driving: 'Driving Route',
    titleBold: 'DrawingMode:',
    titleNormal: 'Click to draw paths or select pins',
    more: 'More Instructions',
  },
  KeylessError: {
    schedulingConflict: 'You can not add the same Prospect Tour on the same apartment for future visits.',
    okError: 'OK',
    uniqueIdError: 'Event uniqueID already exists',
    expiredPin: 'Kastle pin expired',
  },
  ClearKeylessAccessModal: {
    heading: 'Error: Keyless access already enabled for existing tour',
    body: 'Keyless access cannot be granted to prospects at the same unit(s) at the same community for multiple tour times. You will need to revoke keyless access for the following tour(s) in order to generate keyless access for this tour. Please note: If cleared, the prospect’s tours will be un-notified and will no longer be able to start the affected tour(s):',
    error: 'Error',
    errorText: 'An error has occurred. Please click RETRY to clear the keyless access.',
    success: 'Success!',
    successText: 'The conflicting keyless access has been resolved.',
    loadingText: 'Removing Keyless Access',
  },
  TextAreaModal: {
    subheading: [
      'Provide pre-arrival instructions to your prospect which will appear in the app before they begin the tour.',
      'Instructions should include parking location and gate code (if applicable).',
      'Spell check and review your instructions to ensure accuracy.',
      'Click "send" to notify your prospect.',
    ],
    placeholder: 'Saved Messages',
    cancel: 'CANCEL',
    submit: (isNote: boolean) => (isNote ? 'Save' : 'Send'),
    textAreaLabel: 'Pre-arrival Instructions',
    emailPlaceholder: 'example@email.com',
    required: 'Required',
    save: 'SAVE',
    delete: 'DELETE',
    saved: 'TEMPLATE SAVED!',
    deleteConfirm: 'Are you sure you want to delete?',
    deleteWarn: 'This action will delete this template for all other leasing consultants. ',
    deleted: 'TEMPLATE DELETED!',
    sent: 'CLOSE',
    notifiedTitle: 'Prospect Notified!',
    notifiedText: 'Your tour has been sent to the prospect.',
    close: 'CLOSE',
  },
  TourSection: {
    agentName: (tour: Tour) =>
      `${isEmpty(tour.leasingAgent?.firstName) ? 'Unassigned' : tour.leasingAgent?.firstName} ${
        isEmpty(tour.leasingAgent?.lastName) ? '' : tour.leasingAgent?.lastName
      }`,
    tourStatus: (tour: Tour) => {
      const text = (() => {
        switch (true) {
          case tour.isModel:
            switch (true) {
              case tour.status === 'Cancelled':
                return 'Cancelled';
              case !!tour?.tourMap?.mapData?.type:
                return 'Model Tour Ready';
              default:
                return 'Empty Model Tour';
            }
          case tour.isAmenity:
            switch (true) {
              case tour.status === 'Cancelled':
                return 'Cancelled';
              case !!tour?.tourMap?.mapData?.type:
                return 'Amenity Tour Ready';
              default:
                return 'Empty Amenity Tour';
            }
          case tour.status === 'Cancelled':
            return 'Cancelled';
          case !!tour?.tourMap?.prospectNotifiedAt:
            return 'Notified';
          case !!tour?.tourMap?.mapData?.type:
            return 'Map Created';
          default:
            return '';
        }
      })();
      const date = (() => {
        if (tour?.tourMap?.prospectNotifiedAt) {
          return moment(tour.tourMap.prospectNotifiedAt).format('MM/DD/YY h:mma');
        } else if (tour?.tourMap?.cancelledAt) {
          return moment(tour.tourMap.cancelledAt).format('MM/DD/YY h:mma');
        } else if (tour?.tourMap?.updatedAt && tour.tourMap.mapData?.type) {
          return moment(tour.tourMap.updatedAt).format('MM/DD/YY h:mma');
        }
      })();
      return text ? `${text}${date ? ` | ${date}` : ''}` : undefined;
    },
    formatTimeStamp: (text: string, timeStamp?: string) => {
      return timeStamp ? `${text} | ${moment(timeStamp).format('MM/DD/YY h:mma')}` : '';
    },
    deleteText: 'Delete Map',
    reviseTour: 'Revise Tour',
    blockMap: (isMapBlockedEnabled: boolean) => (isMapBlockedEnabled ? 'Block Map' : 'Map Blocked'),
  },
  MapPage: {
    notesHeading: 'Admin-Only Notes',
    notesPlaceholder: 'Click text to edit',
    loadMapText: 'Load Map',
    saveMapText: (isSaved: boolean) => (isSaved ? 'Saved!' : 'Save Map'),
    extendTourText: (extended?: Date) => (extended ? `Extended At ${moment(extended).format('h:mma')}` : 'Extend Tour'),
    notifyProspectText: (notified?: Date) =>
      notified ? `Notified on ${moment(notified).format('MM/DD/YY')}` : 'Notify Prospect',
  },
  IdVerificationBadge: {
    text: (idVerification?: IdVerification | null, sfStatus?: Maybe<string>, sfDateTime?: Maybe<string>) => {
      const formattedDate = idVerification ? moment(idVerification?.createdAt).format('MM/DD/YY h:mma') : null;

      switch (true) {
        case idVerification?.isValid:
        case sfStatus === 'Valid':
          return `ID Verified Successfully | ${formattedDate ?? sfDateTime}`;
        case !!idVerification?.errorDetails:
          return `${idVerification?.errorDetails?.internalTitle} | ${formattedDate}`;
        case !!sfStatus:
          return `${sfStatus} | ${sfDateTime}`;
        default:
          return 'ID Verification Needed';
      }
    },
  },
  SidebarInfo: {
    pricingHeading: (date?: Maybe<string>) => (date ? `Pricing On ${moment(date).format('MM/DD/YY')}` : 'Pricing'),
    pricing: (rentAmount?: Maybe<number>) =>
      rentAmount ? `$${rentAmount.toLocaleString()} / mo` : 'No Pricing Available',
    leaseTerm: (months?: Maybe<number>) => (months ? `${months} Months` : ''),
  },
  BaseMapsEditor: {
    linkText: 'Base Map',
    notesHeading: 'Admin-Only Notes',
    notesPlaceholder: 'Click text to edit',
    saveButton: (saved: boolean) => (saved ? 'Saved!' : 'Save'),
  },
  CommunitySearch: {
    filterText: (filter: string) => `Sort: ${filter}`,
    lastEditedLabel: 'Last Edited:',
    lastEditedValue: (date: Date) => moment(date).format('MM/DD/YY'),
  },
  PreviousMapCard: {
    loadMapText: (isMapBlocked: boolean | undefined) => (!isMapBlocked ? 'use map' : 'unavailable'),
  },
  BaseMapCard: {
    loadMapText: 'use map',
    lastEditedText: 'Last Edited',
    lastEditedValue: (date: Date) => moment(date).format('MM/DD/YY h:mma'),
    pinsText: 'Pins',
  },
  PreviousMapsBar: {
    baseMapHeading: 'Base Map',
    modelMapHeading: 'Model Map',
    yourMapsHeading: (hasPreviousMaps: boolean, loading: boolean) => {
      const firstWord = (() => {
        switch (true) {
          case loading:
            return 'Loading';
          case hasPreviousMaps:
            return 'Your';
          default:
            return 'No';
        }
      })();
      return `${firstWord} Previous Maps`;
    },
    allMapsHeading: (hasPreviousMaps: boolean, loading: boolean) => {
      const firstWord = (() => {
        switch (true) {
          case loading:
            return 'Loading';
          case hasPreviousMaps:
            return 'All';
          default:
            return 'No';
        }
      })();
      return `${firstWord} Maps`;
    },
    closeBarText: 'Load Map',
  },
  Segments: {
    title: 'Segments',
    toggleDrawModeText: (isDrawingMode: boolean, canResume: boolean) =>
      isDrawingMode ? 'Exit Drawing Mode (ESC)' : `${canResume ? 'Resume' : 'Start'} Drawing`,
    addStopText: 'Click a pin to add a stop',
    clearAllText: 'Clear All',
    tipTitle: 'Draw paths between pins to create routes for prospects',
    tip: 'Tip: Place all pins before you start drawing',
    navHelpError: 'Exceeded 100 character limit',
    availabilityError: 'institution id not found',
  },
  Instructions: {
    content: [
      {
        title: 'Creating a Route',
        bullets: [
          'Click on the map to draw the path between pins',
          'Click on a pin to add it as a stop',
          'Start and end the route at the Parking Pin',
          'If you need to exit drawing mode early, press Esc',
        ],
      },
      {
        title: 'Editing a Path',
        bullets: ['Click and drag points on the path to edit', 'To start over, click “Clear All” in the right sidebar'],
      },
      {
        title: 'Path Type',
        bullets: ['Click on an existing path to change between walking and driving'],
      },
    ],
  },
  ContextMenu: {
    changeLineType: (isDriving: boolean) => `Change to ${isDriving ? 'walking' : 'driving'} path`,
  },
  MarkerPropertyEditor: {
    titlePlaceholder: 'Amenity Title',
    titleErrorText: 'Amenity title must be less than 50 characters',
    modelUnitText: 'Model Unit',
    cancelText: 'Cancel',
    saveText: 'Save',
  },
  ModelMapsEditor: {
    linkText: 'Model Map',
    amenityTourText: 'Amenity Tour Map',
    notesHeading: 'Admin-Only Notes',
    notesPlaceholder: 'Click text to edit',
    saveButton: (saved: boolean) => (saved ? 'Saved!' : 'Save'),
  },
  AutoAdd: {
    floors: [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth',
      'Sixth',
      'Seventh',
      'Eight',
      'Ninth',
      'Tenth',
      'Eleventh',
      'Twelfth',
      'Thirteenth',
    ],
  },
  leasingOfficeApartmentId: 'leasing',
  visitedPostFix: '-visited',
};
